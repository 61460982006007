<template>
  <div class="grid mt-3">
    <h2 class="col-12 text-center text-xl font-medium" v-show="titulo != ''">
      {{ titulo }}
    </h2>

    <div class="col-12" v-if="listaProdutos.length == 0">
      <div class="grid">
        <div class="col-12 md:col-3" v-for="i of [1,2,3,4]" :key="i">
          <Skeleton shape="circle" size="60px" class="inline-block va-middle" />
          <div class="inline-block va-middle ml-2" style="width: 70%">
            <Skeleton width="90%" height="30px" />
            <Skeleton width="70%" height="10px" class="mt-1" />
          </div>
          <Skeleton width="100%" height="200px" class="mt-2" />
          <div class="grid mt-1">
            <div class="col-6">
              <Skeleton width="75%" height="30px" />
            </div>
            <div class="col-6 text-right">
              <Skeleton width="75%" height="30px" class="inline-block" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div :class="'col-'+ (12/2) + ' md:col-' + (12/4)" v-for="i of listaProdutos" :key="i.id">
      <ProdutoResumido :value="i" />
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      titulo: "",
      listaProdutos: []
    }
  },
  watch: {
    '$route': function(val) {
      if(val.name == "busca") {
        this.buscar();
      }
    },
    '$root.empresa': {
      handler: function(empresa) {
        if(empresa != null) {
          this.buscar();
        }
      },
      deep: true,
      immediate: true
    },

  },
  methods: {

		buscar: async function() {

      if(this.$root.empresa == null) return;

      this.titulo = 'Resultados para "' + this.$route.params.text + '"';
      
      await new Promise(resolve => setTimeout(resolve, 700));

      if(!(await this.buscaInterna())) {

        // const res = await this.$api.get("/buscaprodutos/"+this.$root.empresa.id+"/"+encodeURIComponent(this.$route.params.text));
        // if(res.data.success){
        //   this.listaProdutos = res.data.data;
        // } else {
        //   this.listaProdutos = [];
        // }

      }
      
		},

    buscaInterna: async function() {

      if(this.$root.conteudo.length == 0) {
        await this.$root.carregaConteudo();
      }

      if(this.$root.conteudo.length == 0)
        return false;

      this.listaProdutos = [];

      const str = this.$route.params.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

      for(const c of this.$root.conteudo) {

        if(typeof c.nome_interno == "undefined")
          c.nome_interno = "";
        else
          c.nome_interno += " (categoria)";

        const categoria = c.nome_interno.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

        for(const i of c.itens) {

          if(typeof i.nome == "undefined")
            i.nome = "";

          if(typeof i.descricao == "undefined")
            i.descricao = "";

          const nome = i.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
          const descricao = i.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
          
          if(categoria.indexOf(str) != -1 || nome.indexOf(str) != -1 || descricao.indexOf(str) != -1) {
            this.listaProdutos.push(i);
          }

        }

      }

      // #region Relacionados //

      let strR = this.$route.params.text.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
      strR = strR.replace(/[^a-zA-Z ]/g, "").trim().split(" ")[0].trim();

      let relacionados = [];

      for(const c of this.$root.conteudo) {

        if(typeof c.nome_interno == "undefined")
          c.nome_interno = "";

        const categoria = c.nome_interno.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

        for(const i of c.itens) {

          const nome = i.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();
          const descricao = i.descricao.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase();

          if(categoria.indexOf(strR) != -1 || nome.indexOf(strR) != -1 || descricao.indexOf(strR) != -1) {
            relacionados.push(i);
          }

        }

      }

      let c_relacionados = 0;
      for(const i of relacionados) {
        if(this.listaProdutos.findIndex((obj) => obj.id == i.id) == -1) {
          this.listaProdutos.push(i);
          if(++c_relacionados == 100)
            break;
        }
      }

      // #endregion //

      return this.listaProdutos.length > 0;

    },

  },
}
</script>

<style>

</style>